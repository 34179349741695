import { post } from "@/utils/request";
// get,
// import Utils from "@/utils/util";
// import config from "@/config";
// const serviceName = Utils.getUrlData("serviceName");
// export const getAccessToken = code => {
//   if (serviceName) {
//     return get(
//       `${config.API_HOST}/fortune/recharge/getAccessToken?code=${code}`
//     );
//   } else {
//     return get(`${config.API_HOST}/thirdPay/api/getAccessToken?code=${code}`);
//   }
// };

// export const getQrCodeUrlStatusFeedBack = uuid => {
//   if (serviceName) {
//     return post(
//       `${config.API_HOST}/fortune/recharge/qrCodeUrlStatusFeedBack?uuid=${uuid}`
//     );
//   } else {
//     return post(
//       `${config.API_HOST}/thirdPay/api/qrCodeUrlStatusFeedBack?uuid=${uuid}`
//     );
//   }
// };

// export const getOrderStatusFeedBack = (orderId, sign) => {
//   if (serviceName) {
//     return post(
//       `${config.API_HOST}/fortune/recharge/orderStatusFeedBack?orderId=${orderId}&status=${sign}`
//     );
//   } else {
//     return post(
//       `${config.API_HOST}/thirdPay/api/orderStatusFeedBack?orderId=${orderId}&status=${sign}`
//     );
//   }
// };
// export const getExpireTimeMills = orderId => {
//   if (serviceName) {
//     return get(
//       `${config.API_HOST}/fortune/recharge/getExpireTimeMills?orderId=${orderId}`
//     );
//   } else {
//     return get(
//       `${config.API_HOST}/thirdPay/api/getExpireTimeMills?orderId=${orderId}`
//     );
//   }
// };

// export const postPayOrder = (orderId, openid, payType) => {
//   if (serviceName) {
//     const uuid = Utils.getUrlData("uuid");
//     const rechargeConfigId = Utils.getUrlData("rechargeConfigId");
//     const selfPackageName = Utils.getUrlData("selfPackageName");
//     return post(
//       `${config.API_HOST}/fortune/recharge/recharging?orderCode=${orderId}${
//         openid ? "&openid=" + openid : ""
//       }&payType=${payType}&selfPackageName=${selfPackageName}&rechargeConfigId=${rechargeConfigId}&uuid=${uuid}`
//     );
//   } else {
//     return post(
//       `${config.API_HOST}/thirdPay/api/payOrder?orderId=${orderId}&openid=${openid}&payType=${payType}`
//     );
//   }
// };
/**
 *请求页面展示的数据
 * @param {string} helpPayToken 获取到的token
 * @returns {object} promise对象
 */
export const getInfoQuery = helpPayToken => {
  return post(
    `${window._API_HOST().API_HOST}/payment/helppay/help/order/info/query`,
    {
      helpPayToken
    }
  );
};
/**
 * 创建订单
 * @param {string} code 微信生成的code
 * @param {number} payChannel 支付渠道
 * @param {number} tunnelCode 支付通道
 * @param {string} helpPaytoken 创建帮付单时url中携带
 * @returns {object} promise对象
 */
export const orderCreate = (code, payChannel, tunnelCode, helpPaytoken) => {
  return post(
    `${window._API_HOST().API_HOST}/payment/helppay/payment/order/create`,
    {
      code: code,
      payChannel: payChannel,
      tunnelCode: tunnelCode,
      helpPayToken: helpPaytoken
    }
  );
};
/**
 * 查询帮付单的状态
 * @param {string} token 本地存储的token值
 * @returns {function} 是个函数
 */
export const getOrderquery = token => {
  return post(
    `${window._API_HOST().API_HOST}/payment/helppay/help/order/query`,
    {
      token
    }
  );
};
