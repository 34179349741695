import axios from "axios";
// export const get = (url, data) => {
//   return new Promise((resolve, reject) => {
//     axios(url, { method: "GET", params: data })
//       .then(res => resolve(res.data))
//       .catch(e => reject(e));
//   });
// };
/**
 * post请求
 * @param {string} url 请求url
 * @param {object} data 请求参数
 * @returns {object} promise对象
 */
export const post = (url, data) => {
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "POST",
      data
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
