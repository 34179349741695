const userAgent = navigator.userAgent.toLowerCase();
/**
 * 判断是否是阿里
 * @returns {boolean} 是否是阿里支付
 */
export const isAli = () => userAgent.indexOf("alipay") > 0;
/**
 * 判断是否是微信
 * @returns {boolean} 是否是微信支付
 */
export const isWx = () => userAgent.indexOf("micromessenger") > 0;
/**
 * 判断是否是qq
 * @returns {boolean} 是否是qq支付
 */
export const isQq = () => userAgent.indexOf("qq") > 0;
export const options = {
  // qq: {
  //     sdk: 'https://webcdn.233leyuan.com/common/qqpay/4.2/mqqjsapi.js',
  //     is: isQq(),
  // },
  wx: {
    sdk: "https://webcdn.233leyuan.com/common/wxpay/1.6.1/wxpayjsapi.js",
    is: isWx()
  },
  ali: {
    sdk: "https://webcdn.233leyuan.com/common/alipay/3.1.1/alipayjsapi.js",
    is: isAli()
  }
};

/**
 * 动态添加sdk
 * @returns {string} 请求哪个sdk
 */
export const getJsSDK = () => {
  for (let [key, value] of Object.entries(options)) {
    if (value.is) {
      addScript(value.sdk);
      return key;
    } else {
      console.error("不在任何浏览器内");
    }
  }
};
/**
 * 动态添加script
 * @param {string} src src的链接
 * @returns {void}
 */
const addScript = src => {
  if (!src) return "";
  const script = document.createElement("script");
  script.src = src;
  document.head.appendChild(script);
};
