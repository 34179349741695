<template>
  <div class="wrap">
    <div class="header">
      <img :src="originator.icon" alt="" />
      <div class="username">
        {{ originator.name }}
        <br />
        发起的支付求助订单
      </div>
    </div>
    <div
      class="content"
      :style="{
        backgroundImage:
          isconstatus !== 1 ? `url(${conbg2})` : `url(${conbg1})`,
        height: isconstatus !== 1 ? '6.62rem' : '3.7rem'
      }"
    >
      <div class="gamemgs">
        <div class="title">帮付金额</div>
        <div class="price">
          ¥ <span>{{ amount }}</span>
        </div>
        <div class="date" v-show="isconstatus === 1 || isconstatus === 2">
          支付剩余时间
          <span>{{ date.hour }}</span
          >: <span>{{ date.min }}</span
          >:
          <span>{{ date.sec }}</span>
        </div>
        <div class="game">
          <div class="l">
            <img :src="game.icon" alt="" />
            <span>{{ game.name }}</span>
          </div>
          <div class="r">
            <span>{{ game.goodsName }}</span>
          </div>
        </div>
      </div>
      <div class="status" v-show="isconstatus !== 1">
        <img
          class="successimg"
          v-if="isconstatus === 4"
          :src="successicon"
          alt=""
        />
        <img class="loadingimg" v-else :src="loadingicon" alt="" />
        <div
          class="desc"
          :style="{ color: isconstatus === 4 ? '#08B658' : '#666666' }"
        >
          {{ isconstatus === 4 ? "已支付成功" : "支付中..." }}
        </div>
        <div class="drawee" v-show="isconstatus === 4">
          <span>{{ draweename }} </span>支付帮付订单
        </div>
      </div>
    </div>
    <div class="btn homebtn" v-show="isconstatus === 1" @click="payfun()">
      帮忙付款
    </div>
    <div class="toast" v-show="istoast">
      <div class="toast-con">
        <div class="title">帮忙付款</div>
        <img
          class="close"
          :src="close"
          alt=""
          @click="() => (istoast = false)"
        />
        <div class="toast-price">
          ￥<span>{{ amount }}</span>
        </div>
        <ul>
          <li
            v-for="(list, index) in paylist"
            :key="index"
            @click="changepaytype(index)"
          >
            <div v-if="list.isshow">
              <div class="l">
                <img :src="list.icon" alt="" />
                {{ list.title }}
              </div>
              <img class="r" :src="list.state ? choosed : choose" alt="" />
            </div>
          </li>
        </ul>
        <div class="btn toast-btn" @click="browsertopay()">立即支付</div>
      </div>
    </div>
  </div>
</template>
<script>
import conbg1 from "assets/images/payhelp/conbg1.png";
import conbg2 from "assets/images/payhelp/conbg2.png";
import successicon from "assets/images/payhelp/success.png";
import loadingicon from "assets/images/payhelp/loading.png";
import close from "assets/images/payhelp/close.png";
import wx from "assets/images/payhelp/wx.png";
import zfb from "assets/images/payhelp/zfb.png";
import choose from "assets/images/payhelp/choose.png";
import choosed from "assets/images/payhelp/choosed.png";
import * as pay from "utils/pay";
import { getInfoQuery, orderCreate, getOrderquery } from "@/api";
import { WX_APPID } from "@/common";
import Utils from "utils/util";

export default {
  /**
   * 这个是页面使用的一些数据返回的参数
   * @returns {void}
   */
  data() {
    return {
      conbg1,
      conbg2,
      successicon,
      loadingicon,
      close,
      choose,
      choosed, //以上是页面中使用到的图片的变量
      draweename: "叮叮当当123", //帮助的支付的人的名称
      originator: {
        icon: "",
        name: ""
      }, //发起人的用户信息
      isconstatus: 1, //支付的订单的状态
      date: {
        hour: "00",
        min: "00",
        sec: "00"
      }, //倒计时时间的展示
      game: {
        icon: "",
        name: "",
        goodsName: "",
        gameid: ""
      }, //游戏的内容数据
      paylist: [
        {
          icon: wx,
          title: "微信支付",
          state: true
        },
        {
          icon: zfb,
          title: "支付宝支付",
          state: false
        }
      ], //支付列表的方式
      istoast: false, //是否弹出支付方式
      token_pay: "", //支付的token创建订单的时候会返回，到时候查询账单的时候会当参数进行请求
      storage_aliPayParam: "", //阿里的上一次存储的数据展示
      amount: 0, //支付的金额
      timer: null, //定时器，展示时间的定时器
      helpPayToken: null,
      isremove: false
    };
  },
  methods: {
    /**
     * 点击浏览器的支付让页面可以
     * @returns {void}
     */
    browsertopay() {
      if (this.paylist[0].state) {
        orderCreate("", 2, 4, this.helpPayToken).then(result => {
          if (result.code === 200) {
            Pandora.send(
              "event_web_open_h5_pay_now_button_click",
              "浏览器打开h5页面-立即支付按钮点击",
              {
                channel: 2,
                order: result.data.bizOrderNo,
                price: this.amount * 100,
                gameid: this.game.gameid
              }
            );
            localStorage.setItem(this.helpPayToken, result.data.helpToken);
            location.href = result.data.wxPayInfo.payUrl;
          } else {
            this.errorHandler(result.code);
          }
        });
      } else {
        this.$_zfb_fun();
      }
    },
    /**
     * 点击支付方式然后展示的页面
     * @param {boolean} ind 支付的类型的小标
     * @returns {void}
     */
    changepaytype(ind) {
      this.paylist.forEach(i => {
        i.state = false;
      });
      this.paylist[ind].state = true;
    },
    /**
     * 失败的时候页面的操作
     * @param {number} code 返回的code值
     * @returns {void}
     */
    errorHandler(code) {
      let desc = "订单已失效，请重新分享~";
      switch (code) {
        case 1001:
          desc = "帮付单不存在";
          break;
        case 1002:
          desc = "帮付单创建失败";
          break;
        case 1003:
          desc = "订单已结束";
          break;
        case 1004:
          desc = "帮付单锁定中";
          break;
        case 1005:
          desc = "帮付单已完成";
          break;
        case 1006:
          desc = "帮付单已取消";
          break;
        case 500:
          desc = "服务器异常";
          break;
        case 1099:
          desc = "订单已失效，请重新分享";
          break;
      }
      this.$router.push({
        path: `/endorder`,
        query: {
          desc: encodeURIComponent(desc)
        }
      });
    },
    /**
     * 去微信支付进行的函数
     * @param {object} result 微信支付的一些参数
     * @returns {void}
     */
    $_wx_func(result) {
      // console.log('$_wx_funcresult', result, orderId);
      const _this = this;
      let wxPayInfo = result.data.wxPayInfo;
      /**
       * 判断是否可以执行微信的函数
       * @returns {void}
       */
      function onBridgeReady() {
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: WX_APPID,
            //公众号名称，由商户传入
            timeStamp: wxPayInfo.timestamp,
            //时间戳，自1970年以来的秒数
            nonceStr: wxPayInfo.nonceStr,
            //随机串
            package: `prepay_id=${wxPayInfo.prepayId}`,
            signType: result.data.signType,
            //微信签名方式：
            paySign: wxPayInfo.sign
          },
          /**
           * 请求微信接口的回调函数
           * @param {object} res 返回的对象
           * @returns {void}
           */
          res => {
            // let sign;
            switch (res.err_msg) {
              // case "get_brand_wcpay_request:fail":
              //   // sign = 'FAILED';
              //   Utils.$toast("支付失败");
              //   // getOrderStatusFeedBack(orderId, sign);
              //   break;
              case "get_brand_wcpay_request:cancel":
                Pandora.send("event_help_pay_fail", "帮付支付失败", {
                  channel: 2,
                  order: result.data.bizOrderNo,
                  price: _this.amount * 100,
                  gameid: _this.game.gameid
                });
                var str = location.href.replace(
                  location.href.slice(
                    location.href.indexOf("?"),
                    location.href.indexOf("#")
                  ),
                  ""
                );
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WX_APPID}&redirect_uri=${encodeURIComponent(
                  str
                )}&connect_redirect=1&response_type=code&scope=snsapi_userinfo&state=STATE#`;
                //   // sign = 'CANCLE';
                //   Utils.$toast("支付取消");
                //   // getOrderStatusFeedBack(orderId, sign);
                break;
              case "get_brand_wcpay_request:ok":
                _this.isremove = true;
                Pandora.send("event_help_pay_success", "帮付支付成功", {
                  channel: 2,
                  order: result.data.bizOrderNo,
                  price: _this.amount * 100,
                  gameid: _this.game.gameid
                });
                _this.reqlist();
                // sign = 'SUCCESS';
                // Utils.$toast("get_brand_wcpay_request:ok");
                // _this.$router.push('/success');
                // getOrderStatusFeedBack(orderId, sign);
                break;
            }
          }
        );
      }
      if (typeof WeixinJSBridge === "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        onBridgeReady();
      }
    },
    /**
     * 支付宝进行支付的时候的操作
     * @returns {void}
     */
    $_zfb_fun() {
      orderCreate("", 1, 4, Utils.getUrlData("helpPayToken")).then(result => {
        if (result.code === 200) {
          Pandora.send(
            "event_web_open_h5_pay_now_button_click",
            "浏览器打开h5页面-立即支付按钮点击",
            {
              channel: 1,
              order: result.data.bizOrderNo,
              price: this.amount * 100,
              gameid: this.game.gameid
            }
          );
          localStorage.setItem(this.helpPayToken, result.data.helpToken);
          location.href = `https://openapi.alipay.com/gateway.do?${result.data.aliPayInfo.aliPayParam}`;
        } else {
          this.errorHandler(result.code);
        }
      });
    },
    /**
     * 判断环境看是去支付还是拉起弹框
     * @returns {void}
     */
    payfun() {
      if (pay.isWx() || pay.isAli()) {
        this.topay();
      } else {
        Pandora.send(
          "event_payment_help_h5_pay_button_click",
          "支付求助h5页面-支付按钮点击",
          { source: 3, price: this.amount * 100, gameid: this.game.gameid }
        );
        this.istoast = true;
      }
    },
    /**
     * 支付宝调用的函数
     * @param {string} aliPayParam 阿里的支付的时候获取的数据
     * @param {string} nativeOrderNo 这个是是阿里返回的一个数据用于埋点储存是订单号吧
     * @returns {void}
     */
    alipay(aliPayParam, nativeOrderNo) {
      const _this = this;
      ap.tradePay(
        {
          orderStr: aliPayParam
        },
        res => {
          console.log(res);
          switch (res.resultCode) {
            // case "get_brand_wcpay_request:fail":
            //   // sign = 'FAILED';
            //   Utils.$toast("支付失败");
            //   // getOrderStatusFeedBack(orderId, sign);
            //   break;
            case "6001":
              Pandora.send("event_help_pay_fail", "帮付支付失败", {
                channel: 1,
                order: nativeOrderNo,
                price: _this.amount * 100,
                gameid: _this.game.gameid
              });
              // _this.helporderquery();
              //   // sign = 'CANCLE';
              //   Utils.$toast("支付取消");
              //   // getOrderStatusFeedBack(orderId, sign);
              _this.reqlist();
              break;
            case "9000":
              _this.isremove = true;
              Pandora.send("event_help_pay_success", "帮付支付成功", {
                channel: 1,
                order: nativeOrderNo,
                price: _this.amount * 100,
                gameid: _this.game.gameid
              });
              _this.reqlist();
              // sign = 'SUCCESS';
              // Utils.$toast("get_brand_wcpay_request:ok");
              // _this.$router.push('/success');
              // getOrderStatusFeedBack(orderId, sign);
              break;
          }
          // ap.alert(res.resultCode);
        }
      );
    },
    /**
     * 去支付
     * @returns {void}
     */
    topay() {
      // 判断环境是拉起toast遮罩还是直接支付
      if (pay.isWx()) {
        orderCreate(this.wx_code, 2, 8, this.helpPayToken).then(result => {
          if (result.code === 200) {
            Pandora.send(
              "event_payment_help_h5_pay_button_click",
              "支付求助h5页面-支付按钮点击",
              {
                source: 1,
                order: result.data.bizOrderNo,
                price: this.amount * 100,
                gameid: this.game.gameid
              }
            );
            localStorage.setItem(this.helpPayToken, result.data.helpToken);
            this.$_wx_func(result);
          } else {
            this.errorHandler(result.code);
          }
        });
      } else if (pay.isAli()) {
        // if (this.storage_aliPayParam) {
        //   this.alipay(
        //     this.storage_aliPayParam.split("_")[0],
        //     this.storage_aliPayParam.split("_")[1]
        //   );
        // } else {
        orderCreate("", 1, 8, this.helpPayToken).then(result => {
          if (result.code === 200) {
            // localStorage.setItem(
            //   `${this.helpPayToken}_aliPayParams`,
            //   `${result.data.aliPayInfo.aliPayParam}_&${
            //     result.data.bizOrderNo
            //   }_&${+new Date()}`
            // );
            //openapi.alipay.com/gateway.do?timestamp=2013-01-01 08:08:08&method=alipay.trade.wap.pay&app_id=24609&sign_type=RSA2&sign=ERITJKEIJKJHKKKKKKKHJEREEEEEEEEEEE&version=1.0&charset=GBK&biz_content=AlipayTradeOrderMobilepayModel
            // location.href = `https://openapi.alipaydev.com/gateway.do?alipay_root_cert_sn=687b59193f3f462dd5336e5abf83c5d8_02941eef3187dddf3d3b83462e1dfcf6&alipay_sdk=alipay-easysdk-java&app_cert_sn=9f90680dc7ffc1166ae993e53fd186e6&app_id=2021003131617330&biz_content=%7B%22body%22%3A%221%E9%92%BB%E7%9F%B3%22%2C%22goods_type%22%3A%220%22%2C%22out_trade_no%22%3A%223022071915404700000015380100028%22%2C%22product_code%22%3A%22QUICK_MSECURITY_PAY%22%2C%22subject%22%3A%221%E9%92%BB%E7%9F%B3%22%2C%22timeout_express%22%3A%2219m%22%2C%22total_amount%22%3A%220.01%22%7D&charset=UTF-8&format=JSON&method=alipay.trade.app.pay&notify_url=https%3A%2F%2Fcallback.233leyuan.com%2Ftest1010.233xyx.com%2Fapiserv%2Fpayment%2Fnotify%2FaliPay&sign=GQh8MugXcLpZ3Fy1i5jXd1iA7HC%2FUFLvn79iFQMirkhKU4ePzcL5N0nIe1yaWDhW8KIygpLECc%2BHua0SXmz4HERs%2Fks5VkSF27aRL20YV6XMo%2B3cuiib69l%2FeSlor24WWBdNzPuqTFvgsUBWidfsieuE5pD2LSzxflCly%2BQYQlIK6Fdy8mEp5KXAv9vt9MwX6TrRwxLJXhDy%2BvXI2xltyUqDrXe%2Fgo9HOsTy4bBmdKMh9r1s8IhmyuEdGnnCuOmtIZfbKP2bkksgNwBsIOUalemmmdwP76c5xwgApixAMqYwhCf1fA0qbrgesU4ireWNdGK6M5WnfjWm96lTy%2BTRbA%3D%3D&sign_type=RSA2&timestamp=2022-07-19+15%3A40%3A47&version=1.0`;
            localStorage.setItem(this.helpPayToken, result.data.helpToken);
            Pandora.send(
              "event_payment_help_h5_pay_button_click",
              "支付求助h5页面-支付按钮点击",
              {
                source: 2,
                order: result.data.bizOrderNo,
                price: this.amount * 100,
                gameid: this.game.gameid
              }
            );
            this.alipay(
              result.data.aliPayInfo.aliPayParam,
              result.data.bizOrderNo
            );
          } else {
            this.errorHandler(result.code);
          }
        });
        // }
      }
    },
    /**
     * 页面的倒计时的时间展示
     * @param {number} timelong 时间戳
     * @returns {void}
     */
    pagedatashow(timelong) {
      this.date = {
        hour:
          parseInt(
            (timelong % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ).toString().length === 1
            ? "0" +
              parseInt((timelong % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            : parseInt((timelong % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        min:
          parseInt((timelong % (1000 * 60 * 60)) / (1000 * 60)).toString()
            .length === 1
            ? "0" + parseInt((timelong % (1000 * 60 * 60)) / (1000 * 60))
            : parseInt((timelong % (1000 * 60 * 60)) / (1000 * 60)),
        sec:
          parseInt((timelong % (1000 * 60)) / 1000).toString().length === 1
            ? "0" + parseInt((timelong % (1000 * 60)) / 1000)
            : parseInt((timelong % (1000 * 60)) / 1000)
      };
    },
    /**
     * 请求列表展示数据
     * @returns {void}
     */
    reqlist() {
      this.token_pay = localStorage.getItem(this.helpPayToken)
        ? localStorage.getItem(this.helpPayToken)
        : "";
      getInfoQuery(this.helpPayToken).then(res => {
        let { code, data } = res;
        if (code === 200) {
          data.status === 1 || data.status === 4
            ? localStorage.removeItem(this.helpPayToken)
            : null;
          this.token_pay = localStorage.getItem(this.helpPayToken)
            ? localStorage.getItem(this.helpPayToken)
            : "";
          // 帮助支付的人的名称
          this.draweename = data.helperName;
          this.originator = {
            icon: data.avatar,
            name: data.nickname
          }; //发起人的用户信息
          // 游戏的信息
          this.game = {
            icon: data.gameIconUrl,
            name: data.gameName,
            goodsName: data.goodsName,
            gameid: data.gameId
          };
          // 支付的价格
          this.amount = data.amount / 100;
          // 支付方式的展示
          this.paylist = [
            {
              icon: wx,
              title: "微信支付",
              state: true,
              isshow: data.channelList.indexOf(2) > -1
            },
            {
              icon: zfb,
              title: "支付宝支付",
              state: false,
              isshow: data.channelList.indexOf(1) > -1
            }
          ];
          // 支付剩余时间的倒计时展示
          let timelong = data.expireTime - +new Date();
          this.timer = setInterval(() => {
            timelong = timelong === 0 ? 0 : timelong - 1000;
            this.pagedatashow(timelong);
            if (timelong === 0) {
              clearInterval(this.timer);
            }
          }, 1000);
          this.pagedatashow(timelong);
          // 页面这个地方展示的订单状态
          if (this.token_pay) {
            this.helporderquery(data.status);
          } else {
            this.isconstatus = data.status;
          }
        } else {
          if (this.token_pay) return;
          this.errorHandler(code);
        }
      });
    },
    /**
     * 请求查询帮付单状态
     * @param {number} status 这个是获取的状态
     * @returns {void}
     */
    helporderquery(status) {
      getOrderquery(this.token_pay).then(res => {
        let { code, data } = res;
        if (code === 200) {
          this.isconstatus = data.helpPayTaskState;
          if (this.isremove) {
            localStorage.removeItem(this.helpPayToken);
            this.token_pay = localStorage.getItem(this.helpPayToken)
              ? localStorage.getItem(this.helpPayToken)
              : "";
          }
          // if (pay.isAli()) {
          //   if (localStorage.getItem(`${this.helpPayToken}_aliPayParams`)) {
          //     // 判断是否是5分钟进行数据的删除
          //     +new Date() -
          //       localStorage
          //         .getItem(`${this.helpPayToken}_aliPayParams`)
          //         .split("_")[2] >
          //     300000
          //       ? localStorage.setItem(`${this.helpPayToken}_aliPayParams`, "")
          //       : null;
          //     this.storage_aliPayParam = localStorage.getItem(
          //       `${this.helpPayToken}_aliPayParams`
          //     );
          //     this.isconstatus = this.storage_aliPayParam
          //       ? 1
          //       : this.isconstatus;
          //   }
          // }
        } else {
          this.isconstatus = status;
          this.errorHandler(code);
        }
      });
    }
  },
  /**
   * 初始化函数执行的参数
   * @returns {void}
   */
  mounted() {
    // 初始化sdk;
    pay.getJsSDK();
    window.Pandora.send(
      "config",
      {
        index_type: "wl", //web填 'wl'一般情况；官网的是wl_233leyuan_official_website
        selfpackagename: "com.meta.box", //指定app包名；乐园：com.meta.box,小游戏：com.meta.xyx,官网：com.233leyuan.www
        send_interval: 100, //发送间隔(ms)
        debug: true, //调试模式，vue在非debug模式下会拦截所有错误信息
        env: "prod", //环境 支持 prod, pre, test dev4X版本后为必填项
        collectErrors: false, //收集错误信息开关 3.0.5版本新增，默认是关
        enableBridge: false //JsBridge环境内通过jsBridge上报埋点 默认开启
        //... //其他 (每次请求都会附带)
      },
      {
        zone: "zh" // 使用国内域名或者海外域名 可选择 zh, en 当zone的值设置为en时将会使用海外域名，同时appkey将会作为接口的一级路径拼接在域名后方
      }
    );

    // start必须调用
    window.Pandora.send("start");
    this.helpPayToken = Utils.getUrlData("helpPayToken");
    let source;
    if (pay.isWx()) {
      const redirect_url = encodeURIComponent(window.location.href);
      const code = Utils.getUrlData("code");
      this.wx_code = Utils.getUrlData("code");
      const state = Utils.getUrlData("state");
      if (!code && !state) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WX_APPID}&redirect_uri=${redirect_url}&connect_redirect=1&response_type=code&scope=snsapi_userinfo&state=STATE#`;
      } else {
        this.reqlist();
      }
      source = 1;
    } else if (pay.isAli()) {
      source = 2;
    } else {
      source = 3;
    }
    Pandora.send("event_h5_page_show", "h5页面展示", { source });

    if (!pay.isWx()) {
      this.reqlist();
    }
  },
  /**
   * 销毁的时候执行的函数
   * @returns {void}
   */
  destroyed() {
    clearInterval(this.timer);
  }
};
</script>
<style lang="less" scoped>
.wrap {
  width: 100vw;
  height: 100vh;
  background: #f5f5f5;
  color: #333333;
  position: relative;
  overflow: scroll;
  .header {
    width: 100vw;
    height: 4.02rem;
    background-size: 100% 100%;
    background-image: url("~assets/images/payhelp/headerbg.png");
    padding-top: 0.48rem;
    img {
      width: 1.26rem;
      height: 1.26rem;
      display: block;
      margin: 0 auto 0.2rem;
      border-radius: 0.63rem;
      border: 0.02rem solid #ffffff;
      overflow: hidden;
    }
    .username {
      line-height: 0.35rem;
      color: #fff;
      font-size: 0.26rem;
    }
  }
  .content {
    width: 6.88rem;
    background-size: 100% 100%;
    margin: -0.91rem auto 0;
    padding-top: 0.5rem;
    .gamemgs {
      .title {
        font-size: 0.22rem;
        line-height: 0.31rem;
        margin-bottom: 0.25rem;
      }
      .price {
        font-size: 0.58rem;
        font-weight: 700;
        line-height: 0.7rem;
        color: #ff7211;
        font-family: "DIN_Condensed";
      }
      .date {
        margin-bottom: 0.27rem;
        font-family: "DIN_Condensed";
        span {
          // width: 0.24rem;
          // height: 0.28rem;
          border-radius: 0.04rem;
          border: 1px solid #dddddd;
          display: inline-block;
          margin: 0 0.05rem;
          font-size: 0.22rem;
          padding: 0.03rem;
        }
      }
      .game {
        width: 5.76rem;
        margin: 0 auto;
        height: 0.84rem;
        background: #fff7ee;
        border-radius: 0.15rem;
        display: flex;
        padding: 0 0.28rem 0 0.17rem;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        font-size: 0.26rem;
        .l {
          font-weight: 500;
          img {
            width: 0.54rem;
            height: 0.54rem;
            border-radius: 0.14rem;
            overflow: hidden;
            margin-right: 0.16rem;
          }
        }
        .r {
          span {
            color: #ff7211;
          }
        }
      }
    }
    .status {
      .successimg {
        margin-top: 0.52rem;
        width: 1.26rem;
        height: 0.92rem;
        margin-bottom: 0.25rem;
      }
      .loadingimg {
        margin-top: 0.98rem;
        width: 0.8rem;
        height: 0.8rem;
        margin-bottom: 0.3rem;
      }
      .desc {
        margin-bottom: 0.36rem;
        font-weight: 500;
        font-size: 0.26rem;
      }
      .drawee {
        font-size: 0.26rem;
        span {
          font-weight: 600;
        }
      }
    }
  }
  .homebtn {
    position: absolute;
    left: 0.54rem;
    bottom: 0.5rem;
  }
  .btn {
    width: 6.14rem;
    height: 0.88rem;
    background: #ff7211;
    border-radius: 0.44rem;
    text-align: center;
    line-height: 0.88rem;
    color: #fff;
    font-size: 0.32rem;
    font-weight: 600;
  }
  .toast {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    .toast-con {
      width: 100vw;
      position: absolute;
      bottom: 0;
      padding: 0.26rem 0.31rem 0.5rem;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 0.25rem 0.25rem 0 0;
      .title {
        font-size: 0.3rem;
        font-weight: 500;
        line-height: 0.42rem;
        margin-bottom: 0.35rem;
      }
      .close {
        width: 0.38rem;
        position: absolute;
        top: 0.23rem;
        right: 0.23rem;
      }
      .toast-price {
        font-weight: 700;
        font-size: 0.58rem;
        span {
          font-size: 0.8rem;
        }
      }
      li {
        width: 6.2rem;
        margin: 0 auto;
        height: 0.84rem;
        font-size: 0.26rem;
        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .l {
          img {
            width: 0.5rem;
            margin-right: 0.15rem;
          }
        }
        .r {
          width: 0.38rem;
        }
      }
      .toast-btn {
        margin: 1.22rem auto 0;
      }
    }
  }
}
</style>
